import React from "react";
import styled from "styled-components";
// import { COLORS } from "constants/styles";

const StyledProgress = styled.div`
  width: 100%;
  height: 5px;
`;

type ProgressBarProps = {
  percentage: number;
};

const ProgressBar = styled.div<ProgressBarProps>`
  width: ${(props) => `${Math.max(props.percentage, 5)}%`};
  background-color: rgb(62, 208, 245);
  height: 100%;
  transition: width 0.3s ease;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;

type ProgressProps = {
  curr: number;
  total: number;
};

const Progress = ({ curr, total }: ProgressProps) => {
  return (
    <StyledProgress>
      <ProgressBar percentage={(curr / total) * 100} />
    </StyledProgress>
  );
};

export default Progress;
