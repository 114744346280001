import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Flow from "./scenes/Flow";
import { COLORS } from "constants/styles";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
    background-color: rgb(234, 238, 241);
  }

  html,
  body,
  #root {
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    font-size: 1.6rem;
  }

  * {
    box-sizing: border-box;
  }

  @media (max-width: 800px) {
    html {
      font-size: 56.25%;
    }
  }
`;

const Background = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: " ";
    background: linear-gradient(
      to right,
      ${COLORS.vetspire},
      ${COLORS.lightPurple}
    );
    width: 100%;
    height: 500px;
    top: -200px;
    position: absolute;
    z-index: -1;
    transform: skewY(-5deg);
  }
`;

function App() {
  const [org, setOrg] = useState("");
  const [flow, setFlow] = useState("PREREGISTER");

  useEffect(() => {
    const { pathname, hostname } = window.location;

    const orgName = pathname.slice(1);
    setOrg(orgName);

    let host = null;

    if (process.env.NODE_ENV === "development")
      host = localStorage.getItem("site");
    else {
      const hosts = hostname.split(".");
      host = hosts[0];
    }

    if (host === "register") {
      setFlow("PREREGISTER");
    } else if (host === "book") {
      setFlow("BOOK");
    }
  }, []);

  return (
    <Background>
      <GlobalStyle />
      {org && <Flow flow={flow} org={org} />}
    </Background>
  );
}

export default App;
