import React, { useState } from "react";
import { PageComponent, PageFooter, Loading } from "components";
import { useQuery, gql } from "@apollo/client";
import styled from "styled-components";
import GoodDoggy from "assets/good_doggy.svg";
import GoodKitty from "assets/good_kitty.svg";
import { COLORS } from "constants/styles";

type PCProps = {
  isSelected?: boolean;
  addNew?: boolean;
};

const PatientContainer = styled.div<PCProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  position: relative;
  opacity: ${(props) => (props.addNew ? "0.7" : "1")};

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:hover:after {
    content: " ";
    background-color: rgba(35, 118, 229, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
  }

  ${(props) =>
    props.isSelected && {
      backgroundColor: COLORS.blue,
      color: "#FFF",
    }}
`;

const PatientName = styled.div`
  font-weight: 700;
  font-size: 2rem;
`;

const PatientDesc = styled.div`
  font-weight: 300;
`;

const PatientLabel = styled.div`
  text-align: center;
`;

const PatientProfileImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 5px;
  border: 0;
`;

type PatientProps = {
  id: string;
  name?: string;
  breed?: string;
  profileImageUrl?: string;
  species?: string;
  onClick: () => void;
  isSelected: boolean;
};

const Patient = ({
  name,
  breed,
  profileImageUrl,
  species,
  onClick,
  isSelected,
}: PatientProps) => (
  <PatientContainer onClick={() => onClick()} isSelected={isSelected}>
    <PatientProfileImage
      src={profileImageUrl || (species === "canine" ? GoodDoggy : GoodKitty)}
    />
    <PatientLabel>
      <PatientName>{name || "Pet"}</PatientName>
      <PatientDesc>{breed}</PatientDesc>
    </PatientLabel>
  </PatientContainer>
);

const StyledAddNew = styled.div`
  font-weight: 600;
  font-size: 2rem;
`;

type AddNewProps = {
  onClick: () => void;
};

const AddNew = ({ onClick }: AddNewProps) => (
  <PatientContainer addNew onClick={() => onClick()}>
    <StyledAddNew>Add New Pet +</StyledAddNew>
  </PatientContainer>
);

const GET_PATIENTS = gql`
  query getPatients {
    patients {
      id
      name
      breed
      species
      profileImageUrl
    }
  }
`;

const Patients = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  grid-gap: 30px;
  justify-content: center;
`;

const PatientSelect = ({
  hasPrevPage,
  onBack,
  onNext,
  goto,
}: PageComponent) => {
  const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>([]);
  const { data, loading } = useQuery(GET_PATIENTS, {
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;

  return (
    <div>
      <Patients>
        {data?.patients?.map((p: PatientProps) => (
          <Patient
            key={p.id}
            {...p}
            onClick={() => {
              const idx = selectedPatientIds.findIndex((s) => s === p.id);
              if (idx === -1) selectedPatientIds.push(p.id);
              else selectedPatientIds.splice(idx, 1);
              setSelectedPatientIds(selectedPatientIds.slice());
            }}
            isSelected={selectedPatientIds.some((i) => i === p.id)}
          />
        ))}
        <AddNew onClick={() => goto("PATIENT_INFO", {})} />
      </Patients>
      <PageFooter
        hasPrevPage={hasPrevPage}
        hasNextPage
        onBack={onBack}
        onNext={() => onNext()}
        nextLabel="Next"
        disabled={selectedPatientIds.length === 0}
      />
    </div>
  );
};

export default PatientSelect;
