import { API_URL } from "../constants/strings";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

function buildHeaders() {
  return { ...defaultHeaders };
}

function parseJSON(resp: any) {
  return resp.json();
}

export function apiPost(url: string, data: any, parse = true) {
  const body = JSON.stringify(data);

  const promise = fetch(API_URL + "/" + url, {
    method: "post",
    headers: buildHeaders(),
    body,
  });

  if (parse) return promise.then(parseJSON);
  return promise;
}

export function apiGet(url: string) {
  return fetch(API_URL + "/" + url, {
    headers: buildHeaders(),
  }).then(parseJSON);
}
