import React, { useState } from "react";
import styled from "styled-components";
import { Page, Progress } from "components";
import { useQuery, gql } from "@apollo/client";

import ApptType from "./ApptType";
// import Times from "./Times";
import Phone from "./Phone";
import ClientInfo from "./ClientInfo";
import VerifyPhone from "./VerifyPhone";
import PatientInfo from "./PatientInfo";
import PatientSelect from "./PatientSelect";
import Registered from "./Registered";

type PageType = {
  header: (arg0?: string, arg1?: any) => string;
  subheader: (arg0?: string) => string;
  Component: React.ElementType;
  hideNext?: boolean;
};

const BOOKING_FLOW = [
  "LOCATION_SELECT",
  "APPT_TYPE",
  "PROVIDER_SELECT",
  "TIMES",
  "PHONE",
  "PHONE_AUTH",
  "CLIENT_INFO",
  "PATIENT_INFO",
  "CONFIRM_VISIT",
  "CONFIRMED_VISIT",
];

const PREREGISTER_FLOW = [
  "PHONE",
  "PHONE_AUTH",
  "CLIENT_INFO",
  "PATIENT_SELECT",
  // "PATIENT_INFO",
  // "CONSENTS",
  // "COMM_PREFS",
  // "INTAKE",
  "REGISTERED",
];

const CHECKIN_FLOW = [
  "CLIENT_INFO",
  "PATIENT_INFO",
  "CONSENTS",
  "INTAKE",
  "CHECKED_IN",
];

const PAGES: { [key: string]: PageType } = {
  LOCATION_SELECT: {
    header: () => "Hi there!",
    subheader: () => "Where would you like to book?",
    Component: ApptType,
    hideNext: true,
  },
  APPT_TYPE: {
    header: () => "Welcome!",
    subheader: () => "What type of visit would you like to book?",
    Component: ApptType,
    hideNext: true,
  },
  PROVIDER_SELECT: {
    header: () => "Who would you like to see?",
    subheader: () => "Choose your preferred doctor or next available.",
    Component: ApptType,
  },
  TIMES: {
    header: () => "Let's get it scheduled.",
    subheader: () => "Select the time that works best for you.",
    Component: ApptType,
  },
  PHONE: {
    header: (flow) => {
      if (flow === "PREREGISTER") return "Hi! Pre-register for your visit.";
      return "What's your phone number?";
    },
    subheader: (flow) => {
      if (flow === "PREREGISTER")
        return "Enter your phone number to get started.";
      return "We're looking forward to seeing you.";
    },
    Component: Phone,
  },
  PHONE_AUTH: {
    header: () => "Verify your phone number.",
    subheader: () => "Enter in the authorization code we just texted.",
    Component: VerifyPhone,
  },
  CLIENT_INFO: {
    header: (_, { viewer }) =>
      viewer?.givenName ? `Welcome back, ${viewer.givenName}!` : "Hi there!",
    subheader: () => "Confirm your personal details for us.",
    Component: ClientInfo,
  },
  PATIENT_SELECT: {
    header: (flow) => {
      if (flow === "PREREGISTER")
        return "Which pets would you like to register?";
      return "Which pets would you like to book for?";
    },
    subheader: () => "Select from your existing pet family or add a new pet.",
    Component: PatientSelect,
  },
  PATIENT_INFO: {
    header: () => "Verify your pet's information.",
    subheader: () => "Fill in the form below with your pet's info.",
    Component: PatientInfo,
  },
  CONSENTS: {
    header: () => "Consent Form",
    subheader: () => "Please fill in the form and sign below.",
    Component: ApptType,
  },
  COMM_PREFS: {
    header: () => "Let's keep in touch.",
    subheader: () => "Tell us the best way to stay in touch with you.",
    Component: ApptType,
  },
  INTAKE: {
    header: () => "Let's learn a bit about your visit.",
    subheader: () => "Answer these intake questions for us ahead of time.",
    Component: ApptType,
  },
  CONFIRM_VISIT: {
    header: () => "Confirm your visit!",
    subheader: () => "Verify the details and book below.",
    Component: ApptType,
  },
  CONFIRMED_VISIT: {
    header: () => "Your visit is confirmed!",
    subheader: () => "We'll see you on July 24th with Dr. Solomon",
    Component: ApptType,
  },
  REGISTERED: {
    header: () => "You're registered!",
    subheader: () => "We look forward to seeing Zack soon.",
    Component: Registered,
  },
  CHECKED_IN: {
    header: () => "You're checked in!",
    subheader: () => "We look forward to seeing Zack soon.",
    Component: ApptType,
  },
};

const BookerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ProgressContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

type FlowProps = {
  flow: string;
  org: string;
};

const GET_DATA = gql`
  query getData {
    viewer {
      id
      givenName
    }
  }
`;

const GET_AUTH_TOKEN = gql`
  {
    authToken
  }
`;

const Flow = ({ flow, org }: FlowProps) => {
  const [input] = useState({ org });
  const [prevPage, setPrevPage] = useState<number | null>(null);
  const { data: authToken } = useQuery(GET_AUTH_TOKEN);
  const { data } = useQuery(GET_DATA, { skip: !authToken });
  const [curr, setCurr] = useState<string>("PHONE");
  const [pageProps, setPageProps] = useState({});

  const pageFlow =
    flow === "PREREGISTER"
      ? PREREGISTER_FLOW
      : flow === "CHECKIN"
      ? CHECKIN_FLOW
      : BOOKING_FLOW;

  const currPage = PAGES[curr];
  let pageIdx = pageFlow.findIndex((f) => f === curr);
  if (pageIdx === -1) pageIdx = prevPage || 0;

  return (
    <BookerContainer>
      <ProgressContainer>
        <Progress curr={pageIdx + 1} total={pageFlow.length} />
      </ProgressContainer>
      <Page
        key={curr}
        Component={currPage.Component}
        header={currPage.header(flow, data || {})}
        subheader={currPage.subheader(flow)}
        hasNextPage={pageIdx !== pageFlow.length - 1 && !currPage.hideNext}
        hasPrevPage={
          prevPage !== null && pageIdx !== 0 && pageIdx !== pageFlow.length - 1
        }
        onNext={() => {
          if (pageIdx !== pageFlow.length - 1) {
            setPrevPage(pageIdx);
            const nextCurr = pageFlow[pageIdx + 1];
            setCurr(nextCurr);
            setPageProps({});
          }
        }}
        onBack={() => {
          const prevCurr = pageFlow[pageIdx - 1];
          setCurr(prevCurr);
          setPageProps({});
        }}
        goto={(page, pageProps) => {
          setPrevPage(pageIdx);
          setPageProps(pageProps);
          setCurr(page);
        }}
        pageProps={pageProps}
        input={input}
      />
    </BookerContainer>
  );
};

export default Flow;
