import React, { useState } from "react";
import styled from "styled-components";
import { Split } from "./";
import ReactLoading from "react-loading";

type FormProps = {
  children: React.ReactNode;
  data?: any;
  loading?: boolean;
  onChange?: (arg0: any) => void;
};

const FormContainer = styled.div`
  width: 100%;
  font-size: 1.1em;

  > div:not(:last-child) {
    margin-bottom: 20px;
  }

  > .Split:not(:last-child) > div {
    margin-bottom: 20px;
  }
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Form = ({ children, data, loading, onChange }: FormProps) => {
  const [input, setInput] = useState({});
  const refs = [];

  const childrenMapper: any = (child: React.ReactElement) => {
    // If there is a Split, we just need to recursively go down.
    if (!child) return null;

    if (Split === child.type)
      return React.cloneElement(child, {
        children: React.Children.map(child.props.children, childrenMapper),
      });

    // If there is no field on the child, it's no form-controlled, so we ignore.
    if (!child.props.field) return child;

    // Let's get the data value for the child.
    let value = data?.[child.props.field] || null;

    // And morph it if necessary
    if (child.props.inputMorph) value = child.props.inputMorph(value);

    // We'll create and store a ref for this form control.
    const newRef = React.createRef();
    refs.push({ ref: newRef, label: child.props.label });

    // Finally, pass in the overriden props.
    return React.cloneElement(child, {
      value,
      onChange: (v: any) => {
        if (child.props.onChange) {
          child.props.onChange(v);
        }
        const newInput = { ...input, [child.props.field]: v };
        setInput(newInput);
        if (onChange) onChange(newInput);
      },
      ref: newRef,
    });
  };

  if (loading)
    return (
      <StyledLoadingContainer>
        <ReactLoading
          type="spinningBubbles"
          height={60}
          width={60}
          color="#12adfd"
        />
      </StyledLoadingContainer>
    );

  return (
    <FormContainer>
      {React.Children.map(children as React.ReactElement, childrenMapper)}
    </FormContainer>
  );
};

export default Form;
