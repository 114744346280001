import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { API_URL } from "constants/strings";
import { setContext } from "@apollo/client/link/context";

function getAuthToken(cache: any) {
  return cache?.data?.data?.ROOT_QUERY?.authToken;
}

const httpLink = createHttpLink({
  uri: `${API_URL}/yourvet/graphql`,
});

const authLink = setContext((_, { headers, cache }) => {
  const token = getAuthToken(cache);

  return {
    headers: {
      ...headers,
      authorization: (token && token !== "") ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
