import styled from "styled-components";

type SplitProps = {
  gridTemplate?: string;
  children?: React.ReactNode;
};

const StyledSplit = styled.div<SplitProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplate
      ? props.gridTemplate
      : "repeat(auto-fit, minmax(200px, 1fr))"};
  grid-column-gap: 30px;
  width: 100%;
  margin-bottom: 0 !important;
`;

const Split = ({ gridTemplate, children }: SplitProps) => (
  <StyledSplit gridTemplate={gridTemplate} className="Split">
    {children}
  </StyledSplit>
);

export default Split;
