export const SPECIES = [
  { value: "canine", label: "Canine" },
  { value: "feline", label: "Feline" },
  { value: "equine", label: "Equine" },
  { value: "avian", label: "Avian" },
  { value: "porcine", label: "Porcine" },
  { value: "vulpine", label: "Vulpine" },
  { value: "lagomorph", label: "Lagomorph" },
  { value: "reptillian", label: "Reptillian" },
  { value: "ferret", label: "Ferret" },
  { value: "amphibian", label: "Amphibian" },
  { value: "fish", label: "Fish" },
  { value: "hedgehog", label: "Hedgehog" },
  { value: "marsupial", label: "Marsupial" },
  { value: "rodent", label: "Rodent" },
  { value: "exotic", label: "Exotic" },
  { value: "small animal", label: "Small Animal" },
  { value: "human", label: "Human" },
  { value: "other", label: "Other" },
];

export const DOG_BREEDS = [
  "Affenpinscher",
  "Afghan Hound",
  "Aidi",
  "Airedale Terrier",
  "Akbash Dog",
  "Akita",
  "Alano Español",
  "Alaskan Klee Kai",
  "Alaskan Malamute",
  "Alpine Dachsbracke",
  "Alpine Spaniel",
  "American Bulldog",
  "American Cocker Spaniel",
  "American Eskimo Dog",
  "American Foxhound",
  "American Hairless Terrier",
  "American Pit Bull Terrier",
  "American Staffordshire Terrier",
  "American Water Spaniel",
  "Anglo-Français de Petite Vénerie",
  "Appenzeller Sennenhund",
  "Ariege Pointer",
  "Ariegeois",
  "Armant",
  "Armenian Gampr dog",
  "Artois Hound",
  "Australian Cattle Dog",
  "Australian Kelpie",
  "Australian Shepherd",
  "Australian Silky Terrier",
  "Australian Stumpy Tail Cattle Dog",
  "Australian Terrier",
  "Azawakh",
  "Bakharwal Dog",
  "Bagle (Basset Hound/Beagle)",
  "Barbet",
  "Basenji",
  "Basque Shepherd Dog",
  "Basset Artésien Normand",
  "Basset Bleu de Gascogne",
  "Basset Fauve de Bretagne",
  "Basset Hound",
  "Bavarian Mountain Hound",
  "Beagle",
  "Beagle-Harrier",
  "Bearded Collie",
  "Beauceron",
  "Bedlington Terrier",
  "Belgian Shepherd Dog (Groenendael)",
  "Belgian Shepherd Dog (Laekenois)",
  "Belgian Shepherd Dog (Malinois)",
  "Bergamasco Shepherd",
  "Berger Blanc Suisse",
  "Berger Picard",
  "Berner Laufhund",
  "Bernese Mountain Dog",
  "Bernedoodle",
  "Bichon Frise",
  "Billy",
  "Black and Tan Coonhound",
  "Black and Tan Virginia Foxhound",
  "Black Norwegian Elkhound",
  "Black Russian Terrier",
  "Bloodhound",
  "Blue Lacy",
  "Blue Paul Terrier",
  "Boerboel",
  "Bohemian Shepherd",
  "Bolognese",
  "Border Collie",
  "Border Terrier",
  "Borzoi",
  "Boston Terrier",
  "Bouvier des Ardennes",
  "Bouvier des Flandres",
  "Boxer",
  "Boykin Spaniel",
  "Bracco Italiano",
  "Braque d'Auvergne",
  "Braque du Bourbonnais",
  "Braque du Puy",
  "Braque Francais",
  "Braque Saint-Germain",
  "Brazilian Terrier",
  "Briard",
  "Briquet Griffon Vendéen",
  "Brittany",
  "Broholmer",
  "Bruno Jura Hound",
  "Bucovina Shepherd Dog",
  "Bugg (Pug/Boston Terrier)",
  "Bull and Terrier",
  "Bull Terrier (Miniature)",
  "Bull Terrier",
  "Bulldog",
  "Bullenbeisser",
  "Bullmastiff",
  "Bully Kutta",
  "Burgos Pointer",
  "Cairn Terrier",
  "Canaan Dog",
  "Canadian Eskimo Dog",
  "Cane Corso",
  "Cardigan Welsh Corgi",
  "Carolina Dog",
  "Carpathian Shepherd Dog",
  "Catahoula Cur",
  "Catalan Sheepdog",
  "Caucasian Shepherd Dog",
  "Cavachon (Cavalier King Charles Spaniel/Bichon Frise)",
  "Cavalier King Charles Spaniel",
  "Central Asian Shepherd Dog",
  "Cesky Fousek",
  "Cesky Terrier",
  "Chesapeake Bay Retriever",
  "Chessadoodle (Chesapeake Bay Retiever/Poodle)",
  "Chien Français Blanc et Noir",
  "Chien Français Blanc et Orange",
  "Chien Français Tricolore",
  "Chien-gris",
  "Chihuahua",
  "Chilean Fox Terrier",
  "Chinese Chongqing Dog",
  "Chinese Crested Dog",
  "Chinese Imperial Dog",
  "Chinook",
  "Chipin (Chihuahua/MinPin)",
  "Chippiparai",
  "Chiweenie (Chihuahua/Dachshund)",
  "Chorkie (Chihuahua/Yorkshire Terrier Mix)",
  "Chow Chow",
  "Cierny Sery",
  "Cimarrón Uruguayo",
  "Cirneco dell'Etna",
  "Clumber Spaniel",
  "Combai",
  "Cockapoo (Cocker Spaniel/Poodle)",
  "Cordoba Fighting Dog",
  "Coton de Tulear",
  "Cretan Hound",
  "Croatian Sheepdog",
  "Cumberland Sheepdog",
  "Curly Coated Retriever",
  "Cursinu",
  "Cão da Serra de Aires",
  "Cão de Castro Laboreiro",
  "Cão Fila de São Miguel",
  "Dachshund",
  "Dalmatian",
  "Dandie Dinmont Terrier",
  "Danish Swedish Farmdog",
  "Deutsche Bracke",
  "Doberman Pinscher",
  "Dorkie (Dachshund/Yorkie)",
  "Dogo Argentino",
  "Dogo Cubano",
  "Dogue de Bordeaux",
  "Drentse Patrijshond",
  "Drever",
  "Dunker",
  "Dutch Shepherd Dog",
  "Dutch Smoushond",
  "East Siberian Laika",
  "East-European Shepherd",
  "Elo",
  "English Bulldog",
  "English Cocker Spaniel",
  "English Foxhound",
  "English Mastiff",
  "English Setter",
  "English Shepherd",
  "English Springer Spaniel",
  "English Toy Terrier (Black &amp; Tan)",
  "English Water Spaniel",
  "English White Terrier",
  "Entlebucher Mountain Dog",
  "Estonian Hound",
  "Estrela Mountain Dog",
  "Eurasier",
  "Field Spaniel",
  "Fila Brasileiro",
  "Finnish Hound",
  "Finnish Lapphund",
  "Finnish Spitz",
  "Flat-Coated Retriever",
  "Formosan Mountain Dog",
  "Fox Terrier (Smooth)",
  "French Bulldog",
  "French Spaniel",
  "Galgo Español",
  "Gascon Saintongeois",
  "German Longhaired Pointer",
  "German Pinscher",
  "German Shepherd",
  "German Shorthaired Pointer",
  "German Spaniel",
  "German Spitz",
  "German Wirehaired Pointer",
  "Giant Schnauzer",
  "Glen of Imaal Terrier",
  "Golden Retriever",
  "Goldendoodle",
  "Gordon Setter",
  "Gran Mastín de Borínquen",
  "Grand Anglo-Français Blanc et Noir",
  "Grand Anglo-Français Blanc et Orange",
  "Grand Anglo-Français Tricolore",
  "Grand Basset Griffon Vendéen",
  "Grand Bleu de Gascogne",
  "Grand Griffon Vendéen",
  "Great Dane",
  "Great Pyrenees",
  "Greater Swiss Mountain Dog",
  "Greek Harehound",
  "Greenland Dog",
  "Greyhound",
  "Griffon Bleu de Gascogne",
  "Griffon Bruxellois",
  "Griffon Fauve de Bretagne",
  "Griffon Nivernais",
  "Hamiltonstövare",
  "Hanover Hound",
  "Hare Indian Dog",
  "Harrier",
  "Havanese",
  "Hawaiian Poi Dog",
  "Himalayan Sheepdog",
  "Hokkaido",
  "Hovawart",
  "Huntaway",
  "Hygenhund",
  "Ibizan Hound",
  "Icelandic Sheepdog",
  "Indian pariah dog",
  "Indian Spitz",
  "Irish Red and White Setter",
  "Irish Setter",
  "Irish Terrier",
  "Irish Water Spaniel",
  "Irish Wolfhound",
  "Istrian Coarse-haired Hound",
  "Istrian Shorthaired Hound",
  "Italian Greyhound",
  "Jack Russell Terrier",
  "Jagdterrier",
  "Jämthund",
  "Kai Ken",
  "Kaikadi",
  "Kanni",
  "Karelian Bear Dog",
  "Karst Shepherd",
  "Keeshond",
  "Kerry Beagle",
  "Kerry Blue Terrier",
  "King Charles Spaniel",
  "King Shepherd",
  "Kintamani",
  "Kishu",
  "Komondor",
  "Kooikerhondje",
  "Koolie",
  "Korean Jindo Dog",
  "Kromfohrländer",
  "Kumaon Mastiff",
  "Kurī",
  "Kuvasz",
  "Kyi-Leo",
  "Labrador Husky",
  "Labrador Retriever",
  "Labradoodle",
  "Lagotto Romagnolo",
  "Lakeland Terrier",
  "Lancashire Heeler",
  "Landseer",
  "Lapponian Herder",
  "Large Münsterländer",
  "Leonberger",
  "Lhasa Apso",
  "Lithuanian Hound",
  "Longhaired Whippet",
  "Löwchen",
  "Mahratta Greyhound",
  "Maltese",
  "Maltipoo (Maltese/Poodle)",
  "Manchester Terrier",
  "Maremma Sheepdog",
  "McNab",
  "Mexican Hairless Dog",
  "Miniature American Shepherd",
  "Miniature Australian Shepherd",
  "Miniature Fox Terrier",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Miniature Shar Pei",
  "Mixed",
  "Molossus",
  "Montenegrin Mountain Hound",
  "Moscow Watchdog",
  "Moscow Water Dog",
  "Mountain Cur",
  "Mucuchies",
  "Mudhol Hound",
  "Mudi",
  "Neapolitan Mastiff",
  "New Zealand Heading Dog",
  "Newfoundland",
  "Norfolk Spaniel",
  "Norfolk Terrier",
  "Norrbottenspets",
  "North Country Beagle",
  "Northern Inuit Dog",
  "Norwegian Buhund",
  "Norwegian Elkhound",
  "Norwegian Lundehund",
  "Norwich Terrier",
  "Old Croatian Sighthound",
  "Old Danish Pointer",
  "Old English Sheepdog",
  "Old English Terrier",
  "Old German Shepherd Dog",
  "Olde English Bulldogge",
  "Otterhound",
  "Pachon Navarro",
  "Paisley Terrier",
  "Pandikona",
  "Papillon",
  "Parson Russell Terrier",
  "Patterdale Terrier",
  "Pekingese",
  "Pembroke Welsh Corgi",
  "Perro de Presa Canario",
  "Perro de Presa Mallorquin",
  "Peruvian Hairless Dog",
  "Petit Basset Griffon Vendéen",
  "Petit Bleu de Gascogne",
  "Phalène",
  "Pharaoh Hound",
  "Phu Quoc ridgeback dog",
  "Picardy Spaniel",
  "Plott Hound",
  "Podenco Canario",
  "Pointer (dog breed)",
  "Polish Greyhound",
  "Polish Hound",
  "Polish Hunting Dog",
  "Polish Lowland Sheepdog",
  "Polish Tatra Sheepdog",
  "Pomeranian",
  "Pomsky (Pomeranian/Husky)",
  "Pont-Audemer Spaniel",
  "Poodle",
  "Porcelaine",
  "Portuguese Podengo",
  "Portuguese Pointer",
  "Portuguese Water Dog",
  "Pomapoo (Pomeranian/Poodle)",
  "Posavac Hound",
  "Pražský Krysařík",
  "Pudelpointer",
  "Pug",
  "Puggle (Pug/Beagle)",
  "Puli",
  "Pumi",
  "Pungsan Dog",
  "Pyrenean Mastiff",
  "Pyrenean Shepherd",
  "Rafeiro do Alentejo",
  "Rajapalayam",
  "Rampur Greyhound",
  "Rastreador Brasileiro",
  "Rat Terrier",
  "Ratonero Bodeguero Andaluz",
  "Redbone Coonhound",
  "Rhodesian Ridgeback",
  "Rottweiler",
  "Rough Collie",
  "Russell Terrier",
  "Russian Spaniel",
  "Russian tracker",
  "Russo-European Laika",
  "Sabueso Español",
  "Saint-Usuge Spaniel",
  "Sakhalin Husky",
  "Saluki",
  "Samoyed",
  "Sapsali",
  "Schapendoes",
  "Schillerstövare",
  "Schipperke",
  "Schweizer Laufhund",
  "Schweizerischer Niederlaufhund",
  "Scotch Collie",
  "Scottish Deerhound",
  "Scottish Terrier",
  "Sealyham Terrier",
  "Segugio Italiano",
  "Seppala Siberian Sleddog",
  "Serbian Hound",
  "Serbian Tricolour Hound",
  "Shar Pei",
  "Sheepadoodle",
  "Shetland Sheepdog",
  "Shiba Inu",
  "Shih Tzu",
  "Shikoku",
  "Shiloh Shepherd Dog",
  "Schnoodle (Schnauzer/Poodle)",
  "Siberian Husky",
  "Silken Windhound",
  "Sinhala Hound",
  "Skye Terrier",
  "Sloughi",
  "Slovak Cuvac",
  "Slovakian Rough-haired Pointer",
  "Small Greek Domestic Dog",
  "Small Münsterländer",
  "Smooth Collie",
  "South Russian Ovcharka",
  "Southern Hound",
  "Spanish Mastiff",
  "Spanish Water Dog",
  "Spinone Italiano",
  "Sporting Lucas Terrier",
  "St. Bernard",
  "St. John's water dog",
  "Stabyhoun",
  "Staffordshire Bull Terrier",
  "Standard Schnauzer",
  "Stephens Cur",
  "Styrian Coarse-haired Hound",
  "Sussex Spaniel",
  "Swedish Lapphund",
  "Swedish Vallhund",
  "Tahltan Bear Dog",
  "Taigan",
  "Talbot",
  "Tamaskan Dog",
  "Teddy Roosevelt Terrier",
  "Telomian",
  "Tenterfield Terrier",
  "Thai Bangkaew Dog",
  "Thai Ridgeback",
  "Tibetan Mastiff",
  "Tibetan Spaniel",
  "Tibetan Terrier",
  "Tornjak",
  "Tosa",
  "Toy Bulldog",
  "Toy Fox Terrier",
  "Toy Manchester Terrier",
  "Toy Trawler Spaniel",
  "Transylvanian Hound",
  "Treeing Cur",
  "Treeing Walker Coonhound",
  "Trigg Hound",
  "Tweed Water Spaniel",
  "Tyrolean Hound",
  "Vizsla",
  "Volpino Italiano",
  "Weimaraner",
  "Welsh Sheepdog",
  "Welsh Springer Spaniel",
  "Welsh Terrier",
  "West Highland White Terrier",
  "West Siberian Laika",
  "Westphalian Dachsbracke",
  "Wetterhoun",
  "Wheaton Terrier",
  "Whippet",
  "White Shepherd",
  "Wolf Hybrid",
  "Wire Fox Terrier",
  "Wirehaired Pointing Griffon",
  "Wirehaired Vizsla",
  "Yorkipoo (Yorkie/Poodle)",
  "Yorkshire Terrier",
  "Šarplaninac",
  "Other",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const CAT_BREEDS = [
  "Abyssinian",
  "Aegean",
  "American Bobtail",
  "American Curl",
  "American Shorthair",
  "American Wirehair",
  "Arabian Mau",
  "Asian",
  "Asian Semi-longhair",
  "Australian Mist",
  "Balinese",
  "Bambino",
  "Bengal",
  "Birman",
  "Bombay",
  "Brazilian Shorthair",
  "British Longhair",
  "British Semi-longhair",
  "British Shorthair",
  "Burmese",
  "Burmilla",
  "California Spangled",
  "Chantilly-Tiffany",
  "Chartreux",
  "Chausie",
  "Cheetoh",
  "Colorpoint Shorthair",
  "Cornish Rex",
  "Cymric",
  "Cyprus",
  "Devon Rex",
  "Domestic Short Hair",
  "Domestic Medium Hair",
  "Domestic Long Hair",
  "Donskoy",
  "Dragon Li",
  "Dwarf cat",
  "Egyptian Mau",
  "European Shorthair",
  "Exotic Shorthair",
  "Foldex",
  "German Rex",
  "Havana Brown",
  "Highlander",
  "Himalayan",
  "Japanese Bobtail",
  "Javanese",
  "Khao Manee",
  "Korat",
  "Korean Bobtail",
  "Korn Ja",
  "Kurilian Bobtail",
  "LaPerm",
  "Lykoi",
  "Maine Coon",
  "Manx",
  "Mekong Bobtail",
  "Minskin",
  "Munchkin",
  "Napoleon",
  "Nebelung",
  "Norwegian Forest cat",
  "Ocicat",
  "Ojos Azules",
  "Oregon Rex",
  "Oriental Bicolor",
  "Oriental Longhair",
  "Oriental Shorthair",
  "PerFold",
  "Persian (Modern)",
  "Persian (Traditional)",
  "Peterbald",
  "Pixie-bob",
  "Raas",
  "Ragamuffin",
  "Ragdoll",
  "Russian Blue",
  "Russian White, Black and Tabby",
  "Sam Sawet",
  "Savannah",
  "Scottish Fold",
  "Selkirk Rex",
  "Serengeti",
  "Serrade Petit",
  "Siamese",
  "Siberian",
  "Singapura",
  "Snowshoe",
  "Sokoke",
  "Somali",
  "Sphynx",
  "Suphalak",
  "Thai",
  "Thai Lilac",
  "Tonkinese",
  "Toyger",
  "Turkish Angora",
  "Turkish Van",
  "Ukrainian Levkoy",
  "Ferret",
  "Other",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const EXOTIC_BREEDS = [
  "Frog",
  "Newt",
  "Salamander",
  "Tree Frog",
  "Toad",
  "Wide Mouthed Toad",
  "Tomato Frog",
  "Pixie Frog",
  "Tarantula",
  "Chilean Rose Hair Tarantula",
  "Star Fish",
  "Cockatoo",
  "Dove",
  "Finch",
  "African Gray Parrot",
  "Amazon Parrot",
  "Cockatiel",
  "Conure",
  "Macaw",
  "Other Avian",
  "Bantam Chicken",
  "Canary",
  "Goose",
  "Wild Bird",
  "Duck",
  "Love Bird",
  "Other Parrot",
  "Parakeet",
  "Pigeon",
  "Quaker Parrot",
  "Turkey",
  "Senegal Parrot",
  "Budgie",
  "Cacarique",
  "Chicken",
  "Falcon",
  "Eurasian Eagle Owl",
  "Pionus Parrot",
  "Myna",
  "Leghorn Chicken",
  "Turaco",
  "Rhea",
  "Hawk",
  "Hyacinth Macaw",
  "Lear's Macaw",
  "Blue and Yellow Macaw",
  "Catalina Macaw",
  "Scarlet Macaw",
  "Military Macaw",
  "Green Winged Macaw",
  "Gouldian Finch",
  "Green Cheeked Conure",
  "Hornbill",
  "Indian Ringneck Parrot",
  "Pied Crow",
  "Red-Crested Turaco",
  "Rhode Island Red",
  "Ring-Necked Dove",
  "Rooster",
  "Ostrich",
  "Eclectus Parrot",
  "Pheasant",
  "Quaker Parakeet",
  "Patagonian Conure",
  "Ring-Necked Parakeet",
  "Emu",
  "Peafowl",
  "Crested Corona Canary",
  "Jenday Conure",
  "Sun Conure",
  "Cape Parrot",
  "Yellow Napped Parrot",
  "Umbrella Cockatoo",
  "Gold Capped Conure",
  "Goffin's Cockatoo",
  "Parrotlett",
  "Malinois Canary",
  "Timneh Parrot",
  "Lory",
  "Cooper Hawk",
  "Great Horned Owl",
  "Robin",
  "Starling",
  "Owl",
  "Red Tailed Hawk",
  "Northern Saw-Whet Owl",
  "Woodpecker",
  "Grackle",
  "Sharp-Shinned Hawk",
  "Severe (Chestnut-Fronted) Macaw",
  "Peregrine Falcon",
  "Bald Eagle",
  "Quail",
  "Swan",
  "Plymouth Rock (Barred Rock)",
  "Blue Crowned Conure",
  "Common Poorwill",
  "Kestrel",
  "Mallard",
  "Albatross",
  "Silkie Chicken",
  "Other Crow",
  "Lorikeet",
  "Meyer's Parrot",
  "Red Lored Parrot",
  "Sparrow",
  "Raptor",
  "Orpington Chicken",
  "Toucan",
  "Morning Dove",
  "Anhinga",
  "Barred Owl",
  "Blue Heron",
  "Blue Jay",
  "Cardinal",
  "Catbird",
  "Cattle Egret",
  "Chimney Swift",
  "Cormorant",
  "Diving Bird",
  "Eggret",
  "Great Blue Heron",
  "Great Egret",
  "Green Heron",
  "Herring Gull",
  "Ibis",
  "Laughing Gull",
  "Limpkin",
  "Loon",
  "Marsh Hen",
  "Mistle Thrush",
  "Mocking Bird",
  "Osprey",
  "Pelican",
  "Roseate Spoonbill",
  "Royal Tern",
  "Sandpiper",
  "Sandhill Crane",
  "Scarlet Tanager",
  "Screech Owl",
  "Seagull",
  "Shrike",
  "Skimmer",
  "Snowy Egret",
  "Songbird",
  "Titmouse",
  "Vulture",
  "Wabler",
  "Wood Stork",
  "Yellow Bellied Sapsucker",
  "Yellow Crowned Night Heron",
  "Warbler",
  "Black-Headed Parrot",
  "Crowned Crane",
  "Society Finch",
  "Junco",
  "Polish Laced Chicken",
  "Harris Hawk",
  "Killdeer",
  "House Sparrow",
  "King Fisher",
  "Peking Duck",
  "Ameraucana",
  "American Coot",
  "American Wigeon",
  "Australorp",
  "Blackbird",
  "Bullock's Oriole",
  "Cedar Waxwing",
  "Dark Brahman Chicken",
  "Easter Egger",
  "Flicker",
  "Grebe",
  "Jardine Parrot",
  "Raven",
  "Bulbul",
  "Spotted Sussex",
  "Western Tanager",
  "Meadowlark",
  "Khaki Campbell",
  "Goshawk",
  "Merganser",
  "Swallow",
  "Welsh Harlequin",
  "Moluccan Cockatoo",
  "Eagle",
  "Black-Headed Grosbeak",
  "Chickadee",
  "Zebra Finch",
  "Magpie",
  "Nighthawk",
  "Steller's Jay",
  "Wren",
  "Caique",
  "Black-Headed Caique",
  "Gloster Canary",
  "Houdan Chicken",
  "Eleonora Cockatoo",
  "Black-Capped Conure",
  "Cherry-Headed Conure",
  "Dusky Conure",
  "Halfmoon Conure",
  "Nanday Conure",
  "Queen of Bavaria Conure",
  "Red-Fronted Conure",
  "Red-Headed Conure",
  "Double Yellow Headed Amazon",
  "Euphonia",
  "Chattering Lory",
  "Hahn's Macaw",
  "Harlequin Macaw",
  "Yellow-Collard Macaw",
  "Mexican Redhead Parrot",
  "Alexandrine Parakeet",
  "Canary Winged Parakeet",
  "Derbyan Parakeet",
  "Moustached Parakeets",
  "Fig Parakeet",
  "Maximilian's Parakeet",
  "Red-Legged Honeycreeper",
  "Barn Owl",
  "Hen",
  "Angus",
  "Angus Cross",
  "Buffalo",
  "Water Buffalo",
  "Black Baldy",
  "Brahman",
  "Brown Swiss",
  "Charolais",
  "Dexter",
  "Guernsey",
  "Hereford",
  "Limousin",
  "Longhorn",
  "Mini Brahman",
  "Yak",
  "Holstein",
  "Santa Gertudis",
  "Highland",
  "Shorthorn",
  "Zebu",
  "Bison",
  "Buelingo",
  "Galloway",
  "Jersey Cross",
  "Simmental",
  "Wagyu",
  "Ayrshire",
  "Beefalo",
  "Beefmaster",
  "Belmont Red",
  "Braford",
  "Brangus",
  "Chianina",
  "Droughtmaster",
  "Australian Friesian Sahiwal",
  "Illawarra",
  "Maine-Anjou",
  "Other Bovine",
  "Simbrah",
  "White Face",
  "Jersey",
  "Murray Grey",
  "Hereford Cross",
  "Alpaca",
  "Llama",
  "Camel",
  "Dromedary",
  "Guanaco",
  "Huacaya",
  "Other Camelid",
  "Elk",
  "Alpine",
  "Boer",
  "Boer Cross",
  "Cashmere",
  "Fainting Goat",
  "Jacob's",
  "Kinder Goat",
  "Lamacha",
  "Merino Cross",
  "Mini Oberhasli",
  "Nigerian",
  "Nigerian Cross",
  "Nigerian Dwarf",
  "Nubian",
  "Nubian Cross",
  "Oberhasli",
  "Pygmy",
  "Pygmy Cross",
  "Pygmy Miniature",
  "Pygora",
  "Saanen (Standard)",
  "Toggenburg",
  "Toggenburg Cross",
  "Cashmere Cross",
  "Jersey",
  "Lamancha",
  "Lamancha Cross",
  "Nigerian Dwarf Cross",
  "Other Goat",
  "Nigerian Dwarf Goat",
  "Arapawa",
  "Australian",
  "Brush Goat",
  "Kiko",
  "Australian Goat",
  "Angora Goat",
  "Altai Mountain",
  "Guinea Pig",
  "Peruvian Longhair Guinea Pig",
  "Abyssinian Guinea Pig",
  "Crested Guinea Pig",
  "Other Guinea Pig",
  "Teddy",
  "Smooth",
  "Smooth, Satin",
  "Silkie",
  "Silkie, Smooth",
  "American Guinea Pig",
  "Hairless",
  "Angora Guinea Pig",
  "English Shorthair",
  "Coronet",
  "Merino",
  "Reindeer",
  "Muntjac Deer",
  "Mule Deer",
  "Key Deer",
  "Dolphin",
  "Shark",
  "Bonnet Head Shark",
  "Hermit Crab",
  "Crab",
  "Shrimp",
  "Akhal-teke",
  "American Indian Horse",
  "American Quarter Pony",
  "American Saddler",
  "American Show Horse",
  "American Warmblood",
  "Andalusian",
  "Andalusian Cross",
  "Anglo-arab",
  "Appaloosa",
  "Appaloosa Cross",
  "Appaloosa Mule",
  "Appendix",
  "Appendix Quarter Horse",
  "Arabian Cross",
  "Arabian",
  "Argentine",
  "Argentine Mountain Pony",
  "Argentine Polo Pony",
  "Azteca",
  "Baden-wultemberg",
  "Bashkir Curly",
  "Belgian",
  "Belgian Cross",
  "Brandenburg",
  "Breeding Stock Paint",
  "British Riding Pony",
  "Burro",
  "Canadian Horse",
  "Canadian Sporthorse",
  "Canadian Warmblood",
  "Chincoteague Pony",
  "Cleveland Bay",
  "Clydesdale",
  "Clydesdale Cross",
  "Connemara",
  "Connemara Cross",
  "American Curly Horse",
  "Danish Warmblood",
  "Donkey",
  "Draft",
  "Draft Cross",
  "Dunalino",
  "Dutch Harness Horse",
  "Dutch Thoroughbred",
  "Dutch Warmblood",
  "English Pony",
  "Fell Pony",
  "Fjord",
  "Foxtrotter",
  "Frederiksborg",
  "Friesian",
  "Friesian Cross",
  "German Riding Pony",
  "Grade",
  "Gypsy Vanner",
  "Hackney Pony",
  "Hackney Cross",
  "Hackney Horse",
  "Haflinger",
  "Half Arabian",
  "Hanoverian",
  "Hanoverian Cross",
  "Hessen",
  "Holsteiner",
  "Holsteiner Cross",
  "Hungarian Warmblood",
  "Iberuan Warmblood",
  "Icelandic",
  "Irish Cobb",
  "Irish Draught",
  "Irish Sport Horse",
  "Irish Sport Horse Cross",
  "Irish Warmblood",
  "Kentucky Mountain Horse",
  "Kiger Mustang",
  "Knabstrupper",
  "Lipizzan",
  "Lusitano",
  "Macklinburg",
  "Mammoth Donkey",
  "Mammoth Jack",
  "Minature Mule",
  "Mini Donkey",
  "Miniature Cross",
  "Miniature Donkey",
  "American Miniature Horse",
  "Miniature Mule",
  "Missouri Fox Trotter",
  "Morab",
  "Morgan",
  "Morgan Cross",
  "Mule",
  "Mustang",
  "National Show Horse",
  "New Forest Pony",
  "New Zealand Riding Pony",
  "Nez Perce",
  "Norwegian Fjord",
  "Oldenburg",
  "Oldenburg Cross",
  "Paint",
  "Paint Cross",
  "Palomino",
  "Paso Fino",
  "Percheron",
  "Percheron Cross",
  "Peruvian Paso",
  "Pinto",
  "Pintoloosa",
  "Poa",
  "Poitou Donkey",
  "Polo Pony",
  "Pony",
  "Pony Cross",
  "Quarter Horse",
  "Quarter Horse Cross",
  "Quarter Pony",
  "Rheinland Pfalz Saar Inte",
  "Rhinelander",
  "Rocky Mountain Horse",
  "Saddlebred",
  "Saddlebred Cross",
  "Saxon-Anhalt",
  "Selle Francais",
  "Shagya Arabian",
  "Shetland Cross",
  "Shetland Pony",
  "Shire",
  "Shire Cross",
  "Spanish Horse (PRE)",
  "Spanish Barb",
  "Spanish Norman",
  "Spotted Saddle Horse",
  "Standardbred",
  "Swedish Warmblood",
  "Swedish Warmblood Cross",
  "Tennessee Walking Horse",
  "Thoroughbred",
  "Thoroughbred Cross",
  "Trakehner",
  "Trakehner Cross",
  "Walkaloosa",
  "Warlander",
  "Warmblood",
  "Warmblood Cross",
  "Welsh Cross",
  "Welsh Mountain Pony",
  "Welsh Pony",
  "Westphalian",
  "Zangersheide",
  "Zebra",
  "Zweibrucker",
  "Tennessee Walking Horse Cross",
  "Mini Appaloosa",
  "Paso Fino Cross",
  "Caspian",
  "German Warmblood",
  "Gypsy Vanner Cross",
  "Haflinger Cross",
  "Mountain Pleasure Horse",
  "Pony of the Americas",
  "Standardbred Cross",
  "Other Equine",
  "Burmby",
  "Brumby",
  "Conestoga",
  "Dales Pony",
  "Exmoor Pony",
  "Flemish",
  "Gelderlander",
  "Hessian",
  "Spanish Mustang",
  "Dartmoor Pony",
  "Hinny",
  "Marsh Tacky",
  "Przewalski",
  "Kerry Bog Pony",
  "Tiger Horse",
  "Freiberger",
  "Ardennais",
  "Lipizzaner",
  "Hedgehog",
  "Sugar Glider",
  "Coatimundi",
  "Mole",
  "Meerkat",
  "African Pygmy Hedgehog",
  "Sloth",
  "Sloth - Two-Toed",
  "Kinkajou",
  "Anteater",
  "Sloth - Three-Toed",
  "Goldfish",
  "Betta Fish",
  "Siamese Fighting Fish",
  "Koi",
  "Other Fish",
  "Oscar",
  "Angelfish",
  "Barb",
  "Catfish",
  "Cichlid",
  "Gourami",
  "Guppy",
  "Tetra",
  "Parrot Fish",
  "Blennies",
  "Clownfish",
  "Damselfish",
  "Gobies",
  "Molly",
  "Wrasse",
  "Platy",
  "Plecostomus (Suckerfish)",
  "Kangaroo",
  "Opossum",
  "Wallaroo",
  "Wallaby",
  "Red Kangaroo",
  "Ferret",
  "Mink",
  "Other Ferret",
  "Otter",
  "Ermine/Stoat",
  "Walrus",
  "Other Ovine",
  "Barbados",
  "Cheviot",
  "Columbia",
  "Columbia Cross",
  "Dorper",
  "Dorset",
  "Hampshire",
  "Shropshire",
  "Katahdin",
  "Jacob Horned",
  "Rambouillet",
  "Romney",
  "Southdown",
  "Suffolk",
  "Welsh Mountain Sheep",
  "Bluefaced Leicester",
  "Soay",
  "Soay Cross",
  "Texel",
  "Cambridge",
  "Cotswold",
  "Lincoln",
  "Montadale",
  "Oxford",
  "Tunis",
  "Babydoll",
  "Polypay",
  "Friesian",
  "Friesian Cross",
  "Shetland",
  "Dall Sheep",
  "California Sea Lion",
  "Pot Bellied Pig",
  "Miniature Pig",
  "Pot Bellied Pig Mix",
  "Other Porcine (Pig)",
  "Duroc",
  "Landrace",
  "Berkshire",
  "Kunekune",
  "Hampshire Cross",
  "Yorkshire",
  "Yorkshire Cross",
  "Juliana (Miniature Painted)",
  "Guinea Hog",
  "Boar",
  "Crossbred",
  "Spotted",
  "Spotted Cross",
  "Tamworth",
  "Welsh",
  "Chester White",
  "Gorilla",
  "Lemur",
  "Orangutan",
  "Howler Monkey",
  "Spider Monkey",
  "Patas Monkey",
  "Squirrel Monkey",
  "Other Primate",
  "Monkey",
  "Marmoset",
  "Japanese Snow Macaque",
  "Guenon",
  "Colobus Monkey",
  "Colobus Monkey",
  "Diana Monkey",
  "Elephant",
  "Unknown",
  "Flemish Giant",
  "French Lop",
  "Velveteen",
  "Mini Lop",
  "Netherland Dwarf",
  "New Zealand White",
  "Other Rabbit",
  "Angora Rabbit",
  "Agouti",
  "Angora Cross",
  "Holland Lop",
  "Rex",
  "Cotton Tail",
  "Lion Headed Rabbit",
  "Dwarf Hotot",
  "American Blue Rabbit",
  "Dwarf English Spot",
  "English Spot",
  "Dutch Rabbit",
  "Checkered Giant",
  "Polish",
  "Satin",
  "Silver Fox",
  "Lop Eared",
  "Californian",
  "American Chinchilla Rabbit",
  "Belgian Hare",
  "Harlequin Rabbit",
  "English Lop",
  "Mini Rex",
  "Velveteen Rabbit",
  "Jersey Wooly",
  "American Fuzzy Lop",
  "Argente (Champagne) Rabbit",
  "French Angora Rabbit",
  "Dwarf Angora Rabbit",
  "Mountain Blue Hare",
  "Britannia Petite",
  "Dwarf Rabbit",
  "Palomino Rabbit",
  "Brittamia Petite",
  "American Shorthair",
  "Dutch Lop",
  "Himalayan Rabbit",
  "Norwegian Dwarf",
  "Beveren",
  "d'Argent",
  "Havana",
  "Lilac",
  "Jackrabbit",
  "Florida White",
  "Silver Marten",
  "American Sable",
  "Gecko",
  "Anole",
  "Ball Python",
  "Bearded Dragon",
  "Boa Constrictor",
  "Box Turtle",
  "Chameleon",
  "Green Iguana",
  "Water Turtle",
  "Water Dragon",
  "Common Turtle",
  "Iguana",
  "King Snake",
  "Lizard",
  "Monitor",
  "Other Boa",
  "Other Lizard",
  "Other Python",
  "Painted Turtle",
  "Red Eared Slider Turtle",
  "Red Footed Tortoise",
  "Rhinocerous Iguana",
  "Russian Tortoise",
  "Skink",
  "Snapping Turtle",
  "Sulcata Tortoise",
  "California Desert Tortoise",
  "Burmese Python",
  "Asian Box Turtle",
  "Agama",
  "Lampropeltis Triangulum Gaigeae (Black Milk Snake)",
  "Rat Snake",
  "Other Turtle",
  "Spiny Lizard",
  "Other Snake",
  "Pancake Tortoise",
  "Other Tortoise",
  "Taiwan Beauty Snake",
  "Corn Snake",
  "Tegu",
  "Spiny Tailed Lizard - Uromastyx",
  "Garter Snake",
  "Leopard Tortoise",
  "Map Turtle",
  "Diamondback Terrapin",
  "Black Agama",
  "Flying Gecko",
  "Carpet Snake",
  "Plated Lizard",
  "African Spurred Tortoise",
  "Greek Tortoise",
  "African Sideneck Turtle",
  "Alligator Snapping Turtle",
  "Yellow Belly Turtle",
  "Soft Shell Turtle",
  "Cuvier's Dwarf Caiman",
  "Frilled Dragon",
  "Hognose Snake",
  "Jackson's Chameleon",
  "Armadillo Lizard",
  "Bullsnake",
  "Fat Tailed Gecko",
  "Leopard Gecko",
  "Red Tail Boa",
  "Alligator",
  "Crocodile",
  "Water Monitor",
  "Carpondro",
  "Chinese Big-Head Water Turtle",
  "Emerald Swift",
  "Florida Cooter",
  "Gopher Tortoise",
  "Green Tree Python",
  "Gila Monster",
  "Indigo Snake",
  "Kemp's Ridley Turtle",
  "Komodo Dragon",
  "Leatherback",
  "Black Throated Monitor",
  "Mud Turtle",
  "Blood Python",
  "Jungle Carpet Snake",
  "Rattlesnake",
  "Red-Pointed Turtle",
  "Shingleback",
  "Sri Lanka Star Tortoise",
  "Argentine Chaco Tortoise",
  "Yellow Eared Slider",
  "Galapagos Tortoise",
  "Nile Monitor",
  "Glossy",
  "Sailfin Dragon",
  "Rhinoceros",
  "Field Mouse",
  "Gerbil",
  "Woodchuck",
  "Chinese Dwarf Hamster",
  "Hamster",
  "Mongolian Hamster",
  "Rat",
  "Red Squirrel",
  "Squirrel",
  "Syrian Hamster",
  "Teddy Bear Hamster",
  "Chinchilla",
  "Russian Dwarf Winter White Hamster",
  "Hairless Rat",
  "Groundhog",
  "Hooded Rat",
  "American Blue Rodent",
  "Other Rodent",
  "Capybara",
  "Prairie Dog",
  "Degu",
  "Chipmunk",
  "Flying Squirrel",
  "Porcupine",
  "Armenian Birch Mouse",
  "Dumbo Rat",
  "Fancy Rat",
  "Golden Mouse",
  "Greater Long-Tailed Hamster",
  "Other Gerbil",
  "Other Hamster",
  "Other Mouse",
  "Other Rat",
  "Russian Dwarf Campbells Hamster",
  "Roborovski Dwarf Hamster",
  "Mongolian Gerbil",
  "Black Bear Hamster",
  "Blue Dumbo Rat",
  "Black-Tailed Prairie Dog",
  "Vole",
  "Bushy Tailed Jird",
  "Cheesman's Gerbil",
  "Ciscaucasian Hamster",
  "Eversmann's Hamster",
  "Persian Jird",
  "Arabian Spiny",
  "Egyptian Spiny",
  "Golden Spiny",
  "Panda Bear Hamster",
  "Siberian Hamster",
  "Jumbo Eared Rex Rat",
  "Lanigera (Long-Tail Chinchilla)",
  "Patagonian Mara",
  "Unknown",
  "Panda",
  "Polar Bear",
  "Other Bear",
  "Raccoon",
  "Skunk",
  "Deer",
  "Bobcat",
  "Other Wildlife",
  "Bat",
  "Armadillo",
].map((b) => ({ value: b.toLowerCase(), label: b }));
