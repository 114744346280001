import React, { useState } from "react";
import { Card, InputText, PageComponent, PageFooter } from "components";
import styled from "styled-components";
import { apiPost } from "utils/api";

const PhoneContainer = styled.div`
  width: 100%;
  font-size: 1.6em;
`;

const Phone = ({ hasPrevPage, onBack, onNext, input }: PageComponent) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [loading, setLoading] = useState(false);

  const submitPhone = () => {
    setLoading(true);
    apiPost("yourvet/login", { org: input.org, phoneNumber })
      .then(() => {
        setLoading(false);
        onNext();
      })
      .catch(() => {
        alert("Error occurred. Please try again.");
        setLoading(false);
      });
  };

  return (
    <div>
      <Card noPadding>
        <PhoneContainer>
          <InputText
            type="phone"
            placeholder="Your Phone Number"
            white
            value={phoneNumber}
            onChange={(pn) => setPhoneNumber(pn)}
            onEnter={() => submitPhone()}
          />
        </PhoneContainer>
      </Card>
      <PageFooter
        hasPrevPage={hasPrevPage}
        hasNextPage
        onBack={onBack}
        onNext={() => submitPhone()}
        nextLoading={loading}
        disabled={!phoneNumber || phoneNumber === ""}
      />
    </div>
  );
};

export default Phone;
