import React from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = () => (
  <StyledLoading>
    <ReactLoading
      type="spinningBubbles"
      height={60}
      width={60}
      color="#12adfd"
    />
  </StyledLoading>
);

export default Loading;
