import styled from "styled-components";

type CardProps = {
  noPadding?: boolean;
  width?: string;
};

const Card = styled.div<CardProps>`
  display: flex;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: ${(props) => (props.noPadding ? "0" : "25px")};
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: 0 auto;
`;

export default Card;
