import React from "react";
import { OptionsBlocks, PageComponent } from "components";
import GoodDoggy from "assets/good_doggy.svg";

const types = [
  {
    value: "WELLNESS",
    label: "Wellness Visit",
    image: GoodDoggy,
    description:
      "Routine visit (Vaccinations, heartworm or flea medications, etc.)",
    color: "rgb(46, 88, 236)",
  },
  {
    value: "SICK",
    label: "Sick Visit",
    description:
      "Vomiting, diarrhea, itchy skin/ears, lumps/bumps, teeth issues, etc.",
    color: "rgb(237, 102, 117)",
  },
];

const ApptType = ({ onNext }: PageComponent) => (
  <div>
    <OptionsBlocks options={types} onChange={() => onNext()} />
  </div>
);

export default ApptType;
