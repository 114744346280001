import React from "react";
import { HeaderText, SubheaderText } from "./";
import styled from "styled-components";

const StyledHeader = styled.div`
  margin-bottom: 50px;
  color: #fff;
`;

type HeaderProps = {
  header?: string;
  subheader?: string;
};

const Header = ({ header, subheader }: HeaderProps) => (
  <StyledHeader>
    {header && <HeaderText>{header}</HeaderText>}
    {subheader && <SubheaderText>{subheader}</SubheaderText>}
  </StyledHeader>
);

export default Header;
