import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { COLORS } from "constants/styles";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import Select from "./InputSelect";

const InputContainer = styled.div<InputTextProps>`
  position: relative;

  input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.7em 1em;
    display: block;
    transition: box-shadow 0.2s ease, background-color 0.2s ease,
      outline 0.2s ease;
    font-size: 1.2em;
    font-weight: 700;
    background-color: ${(props) =>
      props.white ? "#FFF" : COLORS.inputBgColor};
    resize: none;
    text-align: ${(props) => props.textAlign || "left"};

    :focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
      background-color: #fff;
    }

    ::placeholder {
      font-weight: 300;
      padding: 0;
      margin: 0;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 10px;
  opacity: 0.7;
  color: inherit;
  display: block;
`;

type InputTextProps = {
  label?: string;
  placeholder?: string;
  white?: boolean;
  onChange?: (arg0: any) => void;
  onEnter?: () => void;
  type?: string;
  value?: string;
  textAlign?: string;
  field?: string;
  inputMorph?: (arg0: any) => string;
  raw?: boolean;
  options?: { label: string; value: string }[];
};

/**
 * Gets the string, or defaults to empty string for null or undefined.
 */
const defaultString = (value: string | undefined | null) =>
  value === null || value === undefined ? "" : value;

const InputText = ({
  label,
  placeholder,
  white,
  onChange,
  onEnter,
  type,
  value: inputValue,
  textAlign,
  raw,
  options,
}: InputTextProps) => {
  const [value, setValue] = useState(defaultString(inputValue));

  const handleChange = (newValue: string | undefined, e?: ChangeEvent) => {
    setValue(newValue || "");

    if (onChange) onChange(raw ? e : newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<string>) => {
    if (e && e.keyCode === 13 && onEnter) onEnter();
  };

  return (
    <InputContainer white={white} textAlign={textAlign}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {type === "phone" ? (
        <PhoneInput
          country="US"
          placeholder={placeholder}
          value={value && value.slice(0, 2) !== "+1" ? "+1" + value : value}
          onChange={handleChange}
          // onBlur={startValidate}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      ) : type === "select" ? (
        <Select
          options={options}
          value={value}
          onChange={(newValue: any) => {
            handleChange(newValue);
            // startValidate();
          }}
          placeholder={placeholder}
          validation={null}
          required={null}
        />
      ) : (
        <input
          type={type || "text"}
          placeholder={placeholder || label}
          value={value}
          onChange={(e) => handleChange(e.target.value, e)}
        />
      )}
    </InputContainer>
  );
};

export default InputText;
