import React from "react";
import styled from "styled-components";
import { COLORS } from "constants/styles";
import ReactLoading from "react-loading";

type StyledType = {
  isLoading?: boolean;
  disabled?: boolean;
  full?: boolean;
};

const StyledButton = styled.button<StyledType>`
  background: transparent;
  border: none;
  outline: none;
  font: inherit;
  background-color: ${COLORS.blue};
  color: rgba(255, 255, 255, 0.9);
  padding: 0.8em 0.5em;
  height: auto;
  border-radius: 10px;
  font-size: 1.5em;
  position: relative;
  text-align: center;
  font-weight: 700;
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s ease, opacity 0.2s ease, color 0.2s ease,
    box-shadow 0.2s ease, transform 0.2s ease, width 0.2s ease;
  width: ${(props) => (props.full ? "100%" : "auto")};

  &:hover {
    background-color: ${(props) =>
      props.isLoading ? "#9BC0F3" : props.disabled ? "default" : "#4B8EE9"};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.2);
    cursor: ${(props) =>
      props.isLoading ? "progress" : props.disabled ? "default" : "pointer"};
    color: rgba(255, 255, 255, 1);
  }

  @media (max-width: 450px) {
    padding: 0.7em ${(props) => (props.isLoading ? "0.5em" : "1.1em")};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.5em;
`;

type ButtonTextProps = {
  hasIcon: boolean;
};

const ButtonText = styled.div<ButtonTextProps>`
  flex-grow: 1;
  padding: 0 ${(props) => (props.hasIcon ? "calc(1.6em + 20px)" : "1.6em")} 0
    1.6em;
  display: flex;
  justify-content: center;
`;

type ButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  full?: boolean;
};

const Button = ({
  children,
  onClick,
  icon,
  loading,
  disabled,
  full,
}: ButtonProps) => (
  <StyledButton
    onClick={() => {
      if (!loading && !disabled) onClick();
    }}
    isLoading={loading}
    disabled={disabled || loading}
    full={full}
  >
    <ButtonText hasIcon={!loading && typeof icon === "string" && icon !== ""}>
      {loading ? (
        <ReactLoading
          type="spinningBubbles"
          height={20}
          width={20}
          color="#FFF"
        />
      ) : (
        children
      )}
    </ButtonText>
    {!loading && icon && (
      <IconContainer>
        <i className="material-icons">{icon}</i>
      </IconContainer>
    )}
  </StyledButton>
);

export default Button;
