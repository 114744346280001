import React from "react";
import { COLORS } from "constants/styles";
import styled from "styled-components";

const SelectContainer = styled.div`
  background-color: ${COLORS.inputBgColor};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0.7em 1em 0.7em 1em;
  display: block;
  transition: box-shadow 0.2s ease, background-color 0.2s ease,
    outline 0.2s ease;
  font-size: 1.2em;
  font-weight: 700;
  height: 50.88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledPlaceholder = styled.span`
  font-weight: 300;
  opacity: 0.6;
`;

const StyledSelect = styled.select`
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 100%;
  cursor: pointer;
`;

type SelectProps = {
  options: any;
  value: any;
  onChange: any;
  placeholder: any;
  validation: any;
  required: any;
};

const Select = ({
  options,
  value,
  onChange,
  placeholder,
  validation,
  required,
}: SelectProps) => {
  const selected = options.find((o: any) => o.value === value);

  return (
    <SelectContainer>
      {selected ? (
        selected.label
      ) : (
        <StyledPlaceholder>{placeholder || "Select..."}</StyledPlaceholder>
      )}
      <i
        className="material-icons"
        style={{
          color: "rgba(0, 0, 0, 0.7)",
        }}
      >
        keyboard_arrow_down
      </i>
      {options && (
        <StyledSelect value={value} onChange={(e) => onChange(e.target.value)}>
          <option key="disabled" value="">
            Select:
          </option>
          {options.map((o: any) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </StyledSelect>
      )}
    </SelectContainer>
  );
};

export default Select;
