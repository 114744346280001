import React from "react";
import styled from "styled-components";
import { Header, Button } from "./";

const PageContainer = styled.div`
  padding: 7%;
  width: 100%;
  max-width: 1100px;
  min-height: 600px;

  @media (max-width: 1000px) {
    padding-top: 100px;
  }
`;

export type PageComponent = {
  header?: string;
  subheader?: string;
  onNext: () => void;
  onBack: () => void;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  input: any;
  goto: (arg0: string, arg1: any) => void;
  pageProps: any;
};

type PageProps = PageComponent & {
  key: string;
  Component: React.ReactType;
};

const BackContainer = styled.div`
  display: flex;
  font-size: 2rem;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  margin-top: 20px;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

type GoBackProps = {
  onClick: () => void;
};

const GoBack = ({ onClick }: GoBackProps) => (
  <BackContainer onClick={() => onClick()}>
    <i className="material-icons">arrow_back</i>
    <div>Go Back</div>
  </BackContainer>
);

const Footer = styled.div`
  margin-top: 30px;
`;

type PageFooterProps = {
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  onNext: () => void;
  onBack: () => void;
  nextLoading?: boolean;
  disabled?: boolean;
  nextLabel?: string;
};

export const PageFooter = ({
  hasPrevPage,
  hasNextPage,
  onNext,
  onBack,
  nextLoading,
  disabled,
  nextLabel,
}: PageFooterProps) => (
  <Footer>
    {hasNextPage && (
      <Button
        onClick={() => onNext()}
        icon="arrow_forward"
        full
        loading={nextLoading}
        disabled={disabled}
      >
        {nextLabel || "Next"}
      </Button>
    )}
    {hasPrevPage && <GoBack onClick={() => onBack()} />}
  </Footer>
);

const Page = ({
  header,
  subheader,
  Component,
  onNext,
  onBack,
  input,
  hasPrevPage,
  goto,
}: PageProps) => (
  <PageContainer>
    <Header header={header} subheader={subheader} />
    <Component
      onNext={onNext}
      onBack={onBack}
      input={input}
      hasPrevPage={hasPrevPage}
      goto={goto}
    />
  </PageContainer>
);

export default Page;
