import React, { useState } from "react";
import { Card, InputText, PageComponent, PageFooter } from "components";
import styled from "styled-components";
import { apiPost } from "utils/api";
import { useApolloClient, gql } from "@apollo/client";

const AuthContainer = styled.div`
  width: 100%;
  font-size: 2em;
  font-weight: 700 !important;
`;

const VerifyPhone = ({ hasPrevPage, onBack, onNext, input }: PageComponent) => {
  const [loading, setLoading] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const client = useApolloClient();

  const verify = () => {
    setLoading(true);
    apiPost("yourvet/verify", { token: authCode, org: input?.org })
      .then(async (resp) => {
        if (resp.authToken) {
          await client.writeQuery({
            query: gql`
              {
                authToken
              }
            `,
            data: {
              authToken: resp.authToken,
            },
          });
          onNext();
        } else {
          alert("Your authorization code is invalid.");
          setLoading(false);
        }
      })
      .catch((e) => {
        alert("Error occurred. Please try again.");
        setLoading(false);
      });
  };

  return (
    <div>
      <Card noPadding width="200px">
        <AuthContainer>
          <InputText
            placeholder="000000"
            value={authCode}
            onChange={(a) => setAuthCode(a)}
            textAlign="center"
            onEnter={() => verify()}
          />
        </AuthContainer>
      </Card>
      <PageFooter
        hasPrevPage={hasPrevPage}
        hasNextPage
        onBack={onBack}
        onNext={() => verify()}
        nextLoading={loading}
        disabled={!authCode || authCode === ""}
        nextLabel="Verify"
      />
    </div>
  );
};

export default VerifyPhone;
