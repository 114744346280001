import React, { useState } from "react";
import InputText from "./InputText";
import PlacesAutocomplete from "react-places-autocomplete";
import styled from "styled-components";

const StyledContainer = styled.div`
  position: relative;
`;

const StyledSuggestions = styled.div`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin: -30px 10px 0;
  font-size: 1.1em;
  position: absolute;
  width: calc(100% - 20px);
  background-color: #fff;
  z-index: 100;
`;

const InputAddress = React.forwardRef((props: any, ref) => {
  const [address, setAddress] = useState(props.value || "");

  const handleChange = (value: string) => {
    setAddress(value);
    if (props.onChange) props.onChange(value);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleChange}
      debounce={500}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <StyledContainer>
          <InputText
            {...props}
            value={address}
            raw
            ref={ref}
            {...getInputProps()}
            suggestions={suggestions}
            getSuggestionItemProps={getSuggestionItemProps}
          />
          {suggestions && suggestions.length > 0 && (
            <StyledSuggestions>
              {suggestions.map((suggestion) => {
                const styles = suggestion.active
                  ? { backgroundColor: "rgba(0, 0, 0, 0.1)" }
                  : {};
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style: {
                        ...styles,
                        padding: "10px 15px",
                        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      },
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </StyledSuggestions>
          )}
        </StyledContainer>
      )}
    </PlacesAutocomplete>
  );
});

export default InputAddress;
