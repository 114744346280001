import React, { useState } from "react";
import {
  Card,
  Form,
  InputText,
  Split,
  PageFooter,
  PageComponent,
  InputAddress,
} from "components";
import { useQuery, gql, useMutation } from "@apollo/client";

const GET = gql`
  query getClient {
    viewer {
      id
      givenName
      familyName
      email
      preferredPhoneNumber {
        id
        name
        value
      }
      primaryAddress {
        id
        text
      }
      clientReferralSource {
        id
      }
    }
    clientReferralSources {
      id
      name
    }
  }
`;

const UPDATE_VIEWER = gql`
  mutation updateViewer($input: ClientInput) {
    updateViewer(input: $input) {
      id
    }
  }
`;

const ClientInfo = ({ hasPrevPage, onBack, onNext }: PageComponent) => {
  const [loading, setLoading] = useState(false);
  const [formInput, setFormInput] = useState({});
  const { data, loading: dataLoading } = useQuery(GET, {
    fetchPolicy: "cache-and-network",
  });
  const [update] = useMutation(UPDATE_VIEWER);

  const updateViewer = () => {
    setLoading(true);

    update({ variables: { input: formInput } })
      .then(({ data: { updateViewer } }) => {
        if (updateViewer?.id) {
          setLoading(false);
          onNext();
        } else {
          setLoading(false);
          alert("Error occurred. Please try again.");
        }
      })
      .catch((e) => {
        setLoading(false);
        alert("Error occurred. Please try again.");
      });
  };

  return (
    <div>
      <Card>
        <Form
          data={data?.viewer}
          loading={dataLoading}
          onChange={(newInput) => setFormInput({ ...formInput, ...newInput })}
        >
          <Split>
            <InputText field="givenName" label="First Name" />
            <InputText field="familyName" label="Last Name" />
          </Split>
          <Split>
            <InputText field="email" label="Email Address" />
            <InputText
              field="preferredPhoneNumber"
              inputMorph={(n) => n?.value}
              label="Mobile Phone Number"
              placeholder="Phone Number"
              type="phone"
            />
          </Split>
          <InputAddress
            label="Home Address"
            field="primaryAddress"
            inputMorph={(n: any) => n?.text}
          />
          {!data?.viewer?.clientReferralSource &&
          data?.clientReferralSources?.length > 0 ? (
            <InputText
              type="select"
              field="clientReferralSourceId"
              label="How did you hear about us?"
              options={
                data?.clientReferralSources?.map((s: any) => ({
                  value: s.id,
                  label: s.name,
                })) || []
              }
            />
          ) : null}
        </Form>
      </Card>
      <PageFooter
        hasPrevPage={hasPrevPage}
        hasNextPage
        onBack={onBack}
        onNext={() => updateViewer()}
        nextLoading={loading}
        nextLabel="Next"
      />
    </div>
  );
};

export default ClientInfo;
