import React from "react";
import styled from "styled-components";

type BlockProps = Option & {
  onClick: () => void;
};

const StyledBlock = styled.div`
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  min-height: 100px;
  display: flex;
  background-color: ${(props) => props.color || "rgb(46, 88, 236)"};
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;

  .next {
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;

    .next {
      opacity: 1;
    }
  }
`;

const BlockText = styled.div`
  flex-grow: 1;
`;

const BlockLabel = styled.div`
  font-weight: 700;
  font-size: 2rem;
  color: #fff;
`;

const BlockDesc = styled.div`
  font-weight: 300;
  font-size: 1.6rem;
  color: #fff;
  max-width: 80%;
`;

const BlockImage = styled.img`
  height: 100%;
  max-width: 100px;
  margin-right: 20px;
  display: block;
`;

const BlockNext = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  i {
    font-size: 3rem;
  }

  width: 60px;
`;

const Block = ({ label, color, image, description, onClick }: BlockProps) => {
  return (
    <StyledBlock color={color} onClick={() => onClick()}>
      {image && <BlockImage src={image} alt={label} />}
      <BlockText>
        <BlockLabel>{label}</BlockLabel>
        {description && <BlockDesc>{description}</BlockDesc>}
      </BlockText>
      <BlockNext className="next">
        <i className="material-icons">arrow_forward</i>
      </BlockNext>
    </StyledBlock>
  );
};

type Option = {
  label: string;
  value: string;
  image?: string;
  description?: string;
  color?: string;
};

type OptionProps = {
  options: Option[];
  onChange: (arg0: string) => void;
};

const OptionsBlocks = ({ options, onChange }: OptionProps) => {
  return (
    <div>
      {options.map((o) => (
        <Block key={o.value} {...o} onClick={() => onChange(o.value)} />
      ))}
    </div>
  );
};

export default OptionsBlocks;
