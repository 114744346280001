import React from "react";
import { PageComponent } from "components";
import GoodDoggy from "assets/good_doggy.svg";
import styled from "styled-components";

const StyledRegister = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

const Registered = ({ onNext }: PageComponent) => (
  <StyledRegister>
    <img src={GoodDoggy} alt="Good doggy" />
  </StyledRegister>
);

export default Registered;
