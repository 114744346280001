import React, { useState } from "react";
import {
  Card,
  Form,
  InputText,
  // InputSelect,
  PageFooter,
  PageComponent,
  Split,
} from "components";
import { useMutation, useQuery, gql } from "@apollo/client";
import {
  DOG_BREEDS,
  SPECIES,
  CAT_BREEDS,
  EXOTIC_BREEDS,
} from "constants/values";

const CREATE_PATIENT = gql`
  mutation createPatient($clientId: ID!, $input: PatientInput!) {
    createPatient(clientId: $clientId, input: $input) {
      id
    }
  }
`;

const GET_VIEWER_ID = gql`
  query getViewerId {
    viewer {
      id
    }
  }
`;

const PatientInfo = ({ hasPrevPage, onBack, input }: PageComponent) => {
  const [loading, setLoading] = useState(false);
  const [formInput, setFormInput] = useState<any>({});
  const { data } = useQuery(GET_VIEWER_ID);
  const [create] = useMutation(CREATE_PATIENT);

  const createPatient = () => {
    setLoading(false);
    create({ variables: { clientId: data.viewer.id, input: formInput } })
      .then(({ data: { createPatient } }) => {
        if (createPatient?.id) {
          setLoading(false);
          onBack();
        } else {
          alert("Error occurred. Please try again.");
          setLoading(false);
        }
      })
      .catch((e) => {
        alert("Error occurred. Please try again.");
        setLoading(false);
      });
  };

  const speciesList = SPECIES;

  const species = formInput["species"];

  return (
    <div>
      <Card>
        <Form
          onChange={(newInput) => setFormInput({ ...formInput, ...newInput })}
        >
          <InputText field="name" label="Name" />
          <Split>
            <InputText
              field="species"
              label="Species"
              type="select"
              options={speciesList}
              placeholder="Select species..."
            />
            <InputText
              field="breed"
              label="Breed"
              type="select"
              options={
                species === "feline"
                  ? CAT_BREEDS
                  : species === "canine"
                  ? DOG_BREEDS
                  : EXOTIC_BREEDS
              }
              placeholder="Select breed..."
            />
          </Split>
        </Form>
      </Card>
      <PageFooter
        hasPrevPage={hasPrevPage}
        hasNextPage
        onBack={onBack}
        onNext={() => createPatient()}
        nextLoading={loading}
        nextLabel="Next"
      />
    </div>
  );
};

export default PatientInfo;
